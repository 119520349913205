
import { reactive, toRefs, onBeforeMount, onMounted, computed } from 'vue'
import 'amfe-flexible'
import { Divider, Image as VanImage } from 'vant'
import rankListAll from './excel'
import { useRoute } from 'vue-router'

export default {
  name: '',
  components: { Divider, VanImage },
  setup() {
    console.log('1-开始创建组件-setup')
    const route = useRoute()
    const data = reactive({
      list: rankListAll.rankList1,
      loading: false,
      isSelected: route.query.type ? route.query.type : 0
    })
    onBeforeMount(() => {
      console.log('2.组件挂载页面之前执行----onBeforeMount')
    })
    onMounted(() => {
      console.log('3.-组件挂载到页面之后执行-------onMounted')
      console.log(rankListAll)
      if (route.query.type) {
        changeList(route.query.type)
      }
    })
    const refData = toRefs(data)

    const rankListB = computed(() => {
      return function (i: Array<any>) {
        if (data.isSelected == 0) {
          return i
        } else {
          const b = JSON.parse(JSON.stringify(i.slice(3)))
          return b
        }
      }
    })

    const rankNo = computed(() => {
      return function (i: number) {
        let i2 = 0
        if (data.isSelected == 0) {
          i2 = i + 1
        } else {
          i2 = i + 4
        }
        if (i2 < 10) {
          return '0' + i2
        } else {
          return i2
        }
      }
    })

    const rankBackGround = (i: any) => {
      if (i == 0) {
        return { background: 'rgba(255, 245, 245, 1)' }
      } else if (i == 2) {
        return { background: ' rgba(235, 246, 252, 1)' }
      } else if (i == 1) {
        return { background: 'rgba(255, 249, 224, 1)' }
      }
    }

    const changeList = (index: any) => {
      data.isSelected = parseInt(index)
      switch (data.isSelected) {
        case 0:
          data.list = rankListAll.rankList1

          break
        case 2:
          data.list = rankListAll.rankList2

          break
        case 1:
          data.list = rankListAll.rankList3

          break
      }
    }
    return {
      ...refData,
      rankNo,
      changeList,
      rankListB,
      rankBackGround
    }
  }
}
