const rankListAll = {
  rankList1: [
    {
      name: '中老文化艺术产业集团（海南）有限公司',
      money: '2,000,000.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList%2Fimage.png%403x.png'
    },
    {
      name: '上海昱洺健康管理有限公司',
      money: '100,000.00',
      image: ''
    },
    {
      name: '苏州嘉卓信息咨询服务有限责任公司',
      money: '100,000.00',
      image: ''
    },
    {
      name: '江苏九尊文化传播有限公司',
      money: '100,000.00',
      image: ''
    },
    { name: '苏州中健健康管理有限公司', money: '100,000.00', image: '' },
    { name: '上海安泰金属表面处理有限公司', money: '100,000.00', image: '' },
    { name: '上海度人教育科技有限公司', money: '100,000.00', image: '' },
    { name: '上海礴安科技有限公司', money: '100,000.00', image: '' },
    { name: '上海在春贸易有限公司', money: '100,000.00', image: '' },
    { name: '陈胡记供应链管理（上海）有限公司', money: '100,000.00', image: '' }
  ],
  rankList2: [
    {
      name: '王耀辉',
      money: '277,236.77',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E7%8E%8B%E8%80%80%E8%BE%89-removebg-preview.png'
    },
    {
      name: '徐燕华',
      money: '216,268.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E5%BE%90%E7%87%95%E5%8D%8E.png'
    },
    {
      name: '吴伟跃',
      money: '189,701.04',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/20230814-172656.jpg'
    },
    {
      name: '张群',
      money: '165,409.94',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E5%BC%A0%E7%BE%A4.png'
    },
    {
      name: '李冠霄',
      money: '157,214.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E6%9D%8E%E5%86%A0%E9%9C%84.png'
    },
    {
      name: '荣先云',
      money: '131,606.15',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E8%8D%A3%E5%85%88%E4%BA%91.png'
    },
    {
      name: '尹志明',
      money: '120,962.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E5%B0%B9%E5%BF%97%E6%98%8E.png'
    },
    {
      name: '黄礼玲',
      money: '115,699.31',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E9%BB%84%E7%A4%BC%E7%8E%B2.png'
    },
    {
      name: '陈志文',
      money: '110,000.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E9%99%88%E5%BF%97%E6%96%87.jpeg'
    },
    {
      name: '张洁（张辰）',
      money: '75,100.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E5%BC%A0%E6%B4%81_%E5%BC%A0%E8%BE%B0.png'
    },
    {
      name: '曾芳敏',
      money: '41,933.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E6%9B%BE%E8%8A%B3%E6%95%8F.png'
    },
    {
      name: '陈娟红',
      money: '31,451.91',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E9%99%88%E5%A8%9F%E7%BA%A2.png'
    },
    {
      name: '徐云波',
      money: '16,149.66',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E5%BE%90%E4%BA%91%E6%B3%A2.png'
    },
    {
      name: '朱燕',
      money: '539.68',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E6%9C%B1%E7%87%95.png'
    }
  ],
  rankList3: [
    {
      name: '徐燕华',
      money: '105,173.86',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E5%BE%90%E7%87%95%E5%8D%8E.png'
    },
    {
      name: '张群',
      money: '105,155.54',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E5%BC%A0%E7%BE%A4.png'
    },
    {
      name: '朱燕',
      money: '100,258.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E6%9C%B1%E7%87%95.png'
    },

    {
      name: '曾芳敏',
      money: '100,018.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E6%9B%BE%E8%8A%B3%E6%95%8F.png'
    },
    {
      name: '徐云波',
      money: '100,010.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E5%BE%90%E4%BA%91%E6%B3%A2.png'
    },
    {
      name: '陈娟红',
      money: '100,000.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E6%9D%9C%E9%95%BF%E5%AE%8F.png'
    },
    {
      name: '杜长洪        ',
      money: '100,000.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E9%99%88%E5%A8%9F%E7%BA%A2.png'
    },
    {
      name: '张洁（张辰）',
      money: '25,055.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E5%BC%A0%E6%B4%81_%E5%BC%A0%E8%BE%B0.png'
    },
    {
      name: '王耀辉',
      money: '10,192.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E7%8E%8B%E8%80%80%E8%BE%89-removebg-preview.png'
    },
    {
      name: '李冠霄',
      money: '10,185.58',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E6%9D%8E%E5%86%A0%E9%9C%84.png'
    },
    {
      name: '荣先云',
      money: '10,157.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E8%8D%A3%E5%85%88%E4%BA%91.png'
    },
    {
      name: '尹志明',
      money: '10,100.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E5%B0%B9%E5%BF%97%E6%98%8E.png'
    },
    {
      name: '黄礼玲',
      money: '10,029.66',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E9%BB%84%E7%A4%BC%E7%8E%B2.png'
    },
    {
      name: '陈志文',
      money: '10,000.00',
      image:
        'https://charity-1305049841.cos.ap-shanghai.myqcloud.com/rankList/%E9%99%88%E5%BF%97%E6%96%87.jpeg'
    }
  ]
}
export default rankListAll
